import React, { useState, useEffect, useContext } from "react";
import styles from "./ForWhomMainPage.module.scss";
import { useFormikContext } from "formik";
import {
  OwcTypography,
  OwcListItem,
  OwcIconButton,
  OwcInput,
  OwcTextarea,
  OwcIcon,
  OwcRadioGroup,
  OwcRadio,
} from "@one/react";
import {
  WHOM_TAB_LABEL,
  MAX_COUNT_CAPACITY,
  ATTRIBUTES_HEADING,
  CLUSTER_DETAILS_ATTRIBUTE_META,
  STANDALONE_ATTRIBUTE_META,
  entryType,
} from "../../../constants";
import { withApollo, compose } from "react-apollo";
import { connect, useSelector } from "react-redux";
import { sortBy } from "lodash";
import PopOverModal from "../../../components/shared/CommonPopOverModal/PopOverModal";
import { GridAutoCompleteStyles } from "../../../components/shared/GridAutoCompleteStyles";
import { formatUserList, uniqList } from "../../../utils/helpers/text/index";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { loadBookingInstruments as loadBookingInstrumentsAction } from "../../booking-instruments/redux/actions";
import { loadPendingReservations as loadPendingReservationsAction } from "../../booking-calendars/redux/actions";
import { CustomFilterContext } from "../../booking-instruments/booking-instruments-custom-filter/context";
import useDialog from "../../../utils/hooks/useDialog";
import PopoverAttributesDetails from "../../../components/shared/CommonPopOverModal/PopoverAttributesDetails";
import PopOverClusterAgGridTable from "../../../components/shared/CommonPopOverModal/PopOverClusterAgGridTable";
import { BookingContext, BookingPopUpContext } from "../../booking/context";
import { ActionTypeSelect } from "@digitallab/grid-common-components";

const ForWhomMainPage = ({ loadBookingInstruments, loadPendingReservations }) => {
  const actionTypes = Object.values(useSelector((store) => store.actionTypes));
  const formik = useFormikContext();
  const [bookingKind, setBookingKind] = useState(formik.values.bookingType === "Run" ? "Run" : "Action");
  const { isEditMode, editBookingData } = useContext(BookingContext);

  const { dispatchAction: bookingPopUpDispatchAction } = useContext(BookingPopUpContext);
  const { setSelectedList } = useContext(CustomFilterContext);
  const usersList = useSelector((state) => state.users.users);
  const storeUser = useSelector((store) => store.user);
  const { openDialog, ...dialogProps } = useDialog();
  const bookingInstruments = useSelector((store) => store.bookingInstruments);
  const { pendingReservations } = useSelector((state) => state.reservations);
  const [arrayList, setArrayList] = useState(bookingInstruments.bookingInstruments);
  const [popoverObject, setpopoverObject] = useState({
    equipmentData: {},
    eventData: {},
  });

  useEffect(() => {
    let pendingReservationIDs = [...pendingReservations];
    pendingReservationIDs = pendingReservationIDs.map((k) => k.resource);
    let result =
      (arrayList.length !== 0 && arrayList.filter((a) => pendingReservationIDs.includes(a.inventoryId))) || [];
    setArrayList(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingReservations]);

  let userDetail = [
    {
      key: storeUser.email,
      familyName: storeUser.familyName,
      givenName: storeUser.givenName,
      name: storeUser.name,
      userId: storeUser.user,
      value: formatUserList(storeUser),
      email: storeUser.email,
    },
  ];

  const bookingCreatedBy = editBookingData?.createdBy ?? "";
  let list = usersList.map((item) => ({
    ...item,
    key: item.email,
    value: formatUserList(item),
  }));

  if (isEditMode) {
    userDetail = list?.filter((k) => k.userId === bookingCreatedBy);
  }
  const fixedOptions = [userDetail[0]];

  const [value, setValue] = useState([
    ...fixedOptions,
    ...formik?.values?.bookingFor?.filter((k) => k.userId !== userDetail[0].userId),
  ]);

  list = list.filter((a) => (isEditMode ? a.userId !== bookingCreatedBy : a.key !== storeUser.email));
  list = sortBy(list, "value");

  let listOption = [];
  listOption = uniqList([...userDetail, ...list], "value");

  useEffect(() => {
    if (isEditMode) {
      let bookedUser = editBookingData?.bookedUsers.map((item) => ({
        ...item,
        key: item.email,
        userId: item.user,
        value: formatUserList(item),
      }));
      let existingUsersID = bookedUser?.map((k) => k.userId);
      let updatedUsers = formik?.values?.bookingFor?.filter((k) => !existingUsersID?.includes(k.userId));
      let updatedBookedUsers = formik?.values?.bookingFor?.map((i) => bookedUser?.find((k) => i.userId === k.userId));
      updatedBookedUsers = updatedBookedUsers?.filter((v) => v !== undefined);
      updatedBookedUsers = updatedBookedUsers?.filter((k) => k.userId !== userDetail[0].userId);
      if (updatedUsers.length === 0) {
        setValue([...fixedOptions, ...updatedBookedUsers.filter((k) => k.userId !== bookingCreatedBy)]);
      } else if (updatedUsers.length > 0) {
        setValue([...fixedOptions, ...updatedBookedUsers, ...updatedUsers]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      bookingFor: value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleOnClose = () => {
    bookingPopUpDispatchAction({
      type: "reset",
    });
  };

  const onClear = (ev) => {
    let result = arrayList.filter((a) => a !== ev?.target?.value);
    let remainingPendingReservation = [...pendingReservations].filter(
      (a) => a.resource !== ev?.target?.value.inventoryId
    );
    if (!result.length) {
      setArrayList([]);
      updateEquipmentDetailsInRedux([]);
      loadPendingReservations([]);
      setSelectedList([]);
    } else {
      setArrayList(result);
      updateEquipmentDetailsInRedux(result);
      loadPendingReservations([...remainingPendingReservation]);
      setSelectedList(() => {
        return result.map((k) => k.inventoryId);
      });
    }
  };
  useEffect(() => {
    if (arrayList.length === 0) {
      formik.setValues({
        ...formik.values,
        description: "",
        project: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrayList]);

  const renderTags = (selectedObjects) => {
    let renderTagValue = selectedObjects?.map((selectedObject) => {
      const chipLabel = selectedObject?.value ?? selectedObject;
      return selectedObjects.length === 1 ? `${chipLabel}` : `${chipLabel}, `;
    });
    let maxCapacity = MAX_COUNT_CAPACITY - renderTagValue[0].length;
    let remainingCapacity = maxCapacity - 7;
    if (remainingCapacity < 0) {
      remainingCapacity = 0;
    }
    return (
      <>
        <OwcTypography className={styles.renderTextField}>{renderTagValue[0]}</OwcTypography>
        {renderTagValue.length > 1 && (
          <OwcTypography style={{ marginLeft: "5px" }}>
            {renderTagValue[1].length > remainingCapacity
              ? `${renderTagValue[1].substring(0, remainingCapacity)}...`
              : renderTagValue[1]}
          </OwcTypography>
        )}
        {renderTagValue.length > 2 ? (
          <span style={{ color: "#0066CC", marginLeft: "5px" }}>+{renderTagValue.length - 2}</span>
        ) : (
          ""
        )}
      </>
    );
  };

  const updateEquipmentDetailsInRedux = async (result) => {
    let bookingInstruments = result;
    loadBookingInstruments({
      bookingInstruments: bookingInstruments,
    });
  };

  const inputChangeHandler = (event) => {
    formik.setFieldValue("bookingType", event.detail === "Run" ? "Run" : undefined);
    setBookingKind(event.detail);
  };

  return (
    <>
      <div className={styles.flexContainer}>
        <div className={styles.flexLeftColumn}>
          <div className={styles.lineTitle}>
            <OwcTypography variant="subtitle2" className={styles.typographyTitle}>
              Equipment details
            </OwcTypography>
          </div>
          <div className={styles.scrollBarlist}>
            {arrayList.length !== 0 &&
              arrayList?.map((item, index) => {
                const option = item.shortIdentifier;
                return (
                  <React.Fragment key={index}>
                    <OwcListItem
                      value={option}
                      key={option}
                      onClick={(e) => {
                        openDialog();
                        setpopoverObject({
                          equipmentData: item,
                          eventData: {},
                        });
                      }}
                      noBorder
                      className="owcListItemBorder"
                    >
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "93%", paddingTop: "7px" }}>{option}</div>
                        <div style={{ width: "8%" }}>
                          <OwcIconButton
                            value={item}
                            key={option}
                            data-testid="clear-selected-button"
                            type="filled"
                            icon="circle_clear"
                            flat
                            onClick={(ev) => {
                              ev.stopPropagation();
                              const isAnyOnGoingEvent = pendingReservations.filter(
                                (x) => x.resource === ev.target.value.inventoryId && x.isOngoingEventFlag
                              );
                              if (isEditMode && isAnyOnGoingEvent.length > 0) {
                                bookingPopUpDispatchAction({
                                  type: "onGoingBooking",
                                  payload: {
                                    onApprove: handleOnClose,
                                  },
                                });
                              } else {
                                bookingPopUpDispatchAction({
                                  type: "futureBooking",
                                  payload: {
                                    onApprove: () => onClear(ev),
                                    onclose: handleOnClose,
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </OwcListItem>
                  </React.Fragment>
                );
              })}
          </div>
        </div>
        <div className={styles.flexRightColumn}>
          <div className={styles.lineTitle}>
            <OwcTypography variant="subtitle2" className={styles.typographyTitle}>
              Booking details
            </OwcTypography>
          </div>
          <div>
            <div
              style={{
                padding: "10px 20px 10px 20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <OwcRadioGroup value={bookingKind} onInputChange={inputChangeHandler}>
                  <OwcRadio value="Run">Run</OwcRadio>
                  <OwcRadio value="Action">Action</OwcRadio>
                </OwcRadioGroup>
                {bookingKind === "Action" && (
                  <ActionTypeSelect actionTypes={actionTypes} formik={formik} name="bookingType" />
                )}
              </div>
            </div>

            <Autocomplete
              multiple
              data-testid="whom-tab-field-booking-for"
              value={value}
              limitTags={2}
              className={styles.bookForFilled}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                setValue([
                  ...fixedOptions,
                  ...newValue.filter((a) => (isEditMode ? a.userId !== bookingCreatedBy : a.key !== storeUser.email)),
                ]);
              }}
              name="bookingFor"
              options={listOption}
              fullWidth={false}
              getOptionLabel={(option) => option.value}
              renderTags={renderTags}
              isOptionEqualToValue={(option, value) => value.userId === option.userId}
              getOptionDisabled={(option) => {
                return option.value === userDetail[0].value;
              }}
              selectOnFocus
              clearOnBlur
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={WHOM_TAB_LABEL.BOOKING_FOR}
                  variant="filled"
                  required={true}
                  error={
                    formik.touched["bookingFor"] === true && formik.values["bookingFor"].length === 0 ? true : false
                  }
                  helpertext={
                    formik.touched["bookingFor"] === true &&
                    formik.values["bookingFor"].length === 0 &&
                    formik.errors["bookingFor"]
                      ? formik.errors["bookingFor"]
                      : ""
                  }
                />
              )}
              sx={GridAutoCompleteStyles?.gridAutoCompleteLableShrink}
            />

            <div className={styles.textFilled}>
              <OwcInput
                data-testid="whom-tab-field-project"
                label={WHOM_TAB_LABEL.PROJECT}
                style={{ width: "100%" }}
                name="project"
                value={formik.values.project}
                onBlur={formik.handleBlur}
                maxLength={30}
                minLength={0}
                onInputChange={(ev) => {
                  formik.setFieldValue("project", ev.detail);
                }}
              >
                {formik.values.project !== "" && (
                  <OwcIcon
                    slot="suffix"
                    type="filled"
                    name="circle_clear"
                    onClick={() => formik.setFieldValue("project", "", true)}
                    id="whom-tab-field-project-clear"
                  />
                )}
              </OwcInput>
            </div>
            <div className={styles.textFilled} style={{ paddingBottom: 0 }}>
              <OwcTextarea
                name="description"
                data-testid="whom-tab-field-description"
                label={WHOM_TAB_LABEL.DESCRIPTION}
                value={formik.values.description}
                onBlur={formik.handleBlur}
                onInputKeyDown={(ev) => {
                  formik.setFieldValue("description", ev.detail);
                }}
                onInputChange={(ev) => {
                  formik.setFieldValue("description", ev.detail);
                }}
                onInputReset={() => formik.setFieldValue("description", "", true)}
                maxLength={200}
                minLength={0}
                cols="18"
                rows={4}
                reset
                errorText={formik.errors["description"]}
                validation-mode="realtime"
                required
              />
            </div>
            {bookingKind === "Run" && (
              <div className={styles.textFilled} style={{ paddingBottom: "20px" }}>
                <OwcInput
                  data-testid="whom-tab-field-eLN-ID"
                  label={WHOM_TAB_LABEL.ELN_ID}
                  style={{ width: "100%" }}
                  name="elnId"
                  value={formik.values.elnId}
                  onBlur={formik.handleBlur}
                  maxLength={30}
                  minLength={0}
                  onInputChange={(ev) => {
                    formik.setFieldValue("elnId", ev.detail);
                  }}
                >
                  {formik.values.project !== "" && (
                    <OwcIcon
                      slot="suffix"
                      type="filled"
                      name="circle_clear"
                      onClick={() => formik.setFieldValue("project", "", true)}
                      id="whom-tab-field-project-clear"
                    />
                  )}
                </OwcInput>
              </div>
            )}
          </div>
        </div>
      </div>
      <PopOverModal
        title={popoverObject?.equipmentData?.shortIdentifier}
        data={popoverObject}
        closeTitle="Close"
        onDialogCloseCallBack={() =>
          setpopoverObject({
            equipmentData: {},
            eventData: {},
          })
        }
        {...dialogProps}
      >
        <PopoverAttributesDetails
          title={ATTRIBUTES_HEADING}
          metaData={
            popoverObject?.equipmentData?.entryType === entryType.cluster
              ? CLUSTER_DETAILS_ATTRIBUTE_META
              : STANDALONE_ATTRIBUTE_META
          }
          data={popoverObject?.equipmentData}
        />
        {popoverObject?.equipmentData?.entryType === entryType.cluster && (
          <PopOverClusterAgGridTable data={[popoverObject?.equipmentData]} />
        )}
      </PopOverModal>
    </>
  );
};

export default compose(
  connect(null, {
    loadBookingInstruments: loadBookingInstrumentsAction,
    loadPendingReservations: loadPendingReservationsAction,
  }),
  withApollo
)(ForWhomMainPage);
