import { toQueryParameter } from "@digitallab/grid-common-components";
import { OwcIconButton, OwcListItem, OwcMenu, OwcTooltip } from "@one/react";
import { isAfter, parseISO } from "date-fns";
import moment from "moment-timezone";
import { useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";

const CoverMenuItem = styled.div`
  height: 0.973480224609375px;
  width: 100%;
  border-radius: 0;
  background: var(--one-color-gray-300);
`;
const BookingMenuAction = ({ params, deleteEquipment, editBooking, setRowEquipmentId, setPopupEnableStatus }) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const user = useSelector((store) => store.user);
  const currentTimeString = moment.tz(user.timezone).utc().format();

  const handleSettingClose = () => {
    setAnchorEl(false);
  };
  const handleSettingClick = (event, params) => {
    setRowEquipmentId?.(params?.data?.id);
    setAnchorEl(event.currentTarget);
  };

  const userDoesNotHaveRightsToWriteInLogbook =
    !user.roles.includes("LogbookAdmin") && !user.roles.includes("LogbookWriter");

  const getLogParamsList = (data) => {
    const { bookingEntries, description, type, eLNid, dateTo } = data;
    const logLinks = [];

    const standaloneEquipmentIdList = bookingEntries
      .filter(({ equipment }) => equipment?.entryType === "STANDALONE-EQUIPMENT")
      .map(({ inventoryId }) => inventoryId);
    const runStatus = isAfter(new Date(), parseISO(dateTo)) ? "COMPLETED" : "PROCESSING";
    const params = {
      description,
    };
    if (type === "Run") {
      params.runStatus = runStatus;
      params.eLNid = eLNid;
    } else {
      params.action = type;
    }
    if (standaloneEquipmentIdList.length > 0) {
      params.equipmentIdList = standaloneEquipmentIdList;
      logLinks.push(`/logs/${type === "Run" ? "run" : "action"}${toQueryParameter(params)}`);
    }
    return logLinks;
  };

  return (
    <>
      <OwcIconButton
        icon="more_vertical"
        type="legacy"
        flat
        onClick={(event) => {
          handleSettingClick(event, params);
        }}
        id={params?.data?.id}
        data-testid="booking-menu-toggle"
      />
      {ReactDOM.createPortal(
        <OwcMenu
          data-testid="simple-menu"
          scrollPadding={false}
          anchor={params?.data?.id}
          visible={anchorEl}
          onClickOutside={handleSettingClose}
          disablePortal={true}
          disabled={!params?.data?.active}
        >
          <OwcListItem
            data-testid="simple-menu-filter"
            onClick={() => {
              setAnchorEl(null);
              setPopupEnableStatus(true);
              editBooking(params?.data);
            }}
            disabled={
              !params?.data?.active ||
              params?.data?.checkInDone ||
              (params?.data?.checkInRequired && params.data.checkInStartsAt <= new Date().getTime() / 1000) ||
              params?.data?.dateTo < currentTimeString
            }
          >
            Edit
          </OwcListItem>
          <div id="AddLogMenuOption">
            <OwcListItem
              data-testid="simple-menu-filter"
              onClick={() => {
                setAnchorEl(null);
                const logParamsList = getLogParamsList(params.data);
                logParamsList.forEach((params) =>
                  window.open(`${process.env.REACT_APP_LOGBOOK_APP_URL}${params}`, "_blank")
                );
              }}
              disabled={userDoesNotHaveRightsToWriteInLogbook}
            >
              Add log
            </OwcListItem>
            {userDoesNotHaveRightsToWriteInLogbook && (
              <OwcTooltip anchor="AddLogMenuOption">You do not have permissions to create log entry.</OwcTooltip>
            )}
          </div>
          <CoverMenuItem />
          <OwcListItem
            data-testid="simple-menu-filter"
            onClick={() => {
              setAnchorEl(null);
              deleteEquipment({
                id: params?.data?.id,
              });
            }}
            disabled={
              !params?.data?.active ||
              params?.data?.dateFrom < currentTimeString ||
              params?.data?.dateTo < currentTimeString
            }
          >
            Cancel
          </OwcListItem>
        </OwcMenu>,
        document.body
      )}
    </>
  );
};
export default BookingMenuAction;
